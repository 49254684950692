<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div>
    <div class="text-black">
      <BCol
        md="12"
        class="d-flex gap-10 w-100 p-0 my-[24px]"
      >
        <b-dropdown
          id="dropdown-1"
          class="filter"
          no-caret
          variant="primary"
          toggle-class="text-decoration-none toggle-custom button-primary rounded-lg py-0 px-1"
        >
          <h5>Filter</h5>
          <template #button-content>
            <b-img
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/filter-search.svg"
              class="height-[20px]"
            />
          </template>
          <b-dropdown
            id="dropdown-2"
            class="w-100"
            toggle-class="text-left"
            variant="none"
            left
            no-caret
            dropright
            menu-class="overflow-y-auto h-[300px]"
          >
            <template #button-content>
              <span class="hover-salmon text-6 w-100">Skill role</span>
            </template>
            <b-dropdown-form style="width: 200px">
              <b-form-checkbox
                v-for="(skill, index) in listSkills"
                :key="index"
                v-model="filterSelected"
                :name="`checkbox-${index}`"
                class="text-6 mb-1"
                :value="skill.id"
                @change="getListTalent()"
              >
                {{ skill.name }}
              </b-form-checkbox>
            </b-dropdown-form>
          </b-dropdown>
          <b-dropdown
            id="dropdown-3"
            class="w-100"
            toggle-class="text-left"
            variant="none"
            left
            no-caret
            dropright
          >
            <template #button-content>
              <span class="hover-salmon text-6 w-100">Rating</span>
            </template>
            <b-dropdown-form style="width: 200px">
              <b-form-checkbox
                v-for="(star, index) in filterRatingOptions"
                :key="index"
                v-model="filterRatingSelected"
                :name="`checkbox-${index}`"
                class="text-6 mb-1"
                :value="star.value"
                @change="getListTalent()"
              >
                <div class="d-flex">
                  {{ star.star }}
                  <feather-icon
                    v-for="n in star.star"
                    :key="n"
                    icon="StarIcon"
                    class="ml-50 text-warning filled"
                  />
                </div>
              </b-form-checkbox>
            </b-dropdown-form>
          </b-dropdown>
          <h5
            class="pb-0 pt-1 font-bold text-danger w-100 cursor-pointer"
            @click="resetFilter()"
          >
            Reset filter
          </h5>
        </b-dropdown>
        <div class="d-flex rounded-lg align-items-center border search-bar">
          <span
            class="k-search-normal-1 h-100 font-bold text-10 pt-[7px] align-middle mx-[5px]"
          />
          <BFormInput
            v-model="keyword"
            placeholder="Cari Nama"
            class="border-0"
            @input="handleSearch()"
          />
        </div>
        <BButton
          tag="router-link"
          to="/talent/create"
          variant="primary"
          class="ml-auto px-3"
        >
          <strong>Tambah</strong>
        </BButton>
      </BCol>
    </div>
    <BOverlay
      id="scroll"
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <div
        id="table"
        style="height: calc(100vh - 340px); overflow-y: scroll;"
        @scroll="handleScroll"
      >
        <b-table-simple>
          <b-thead>
            <b-tr>
              <b-th
                v-for="head in fields"
                :key="head.key"
                :class="head.class"
                class="text-capitalize text-[#333]"
              >
                {{ head.label }}
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <template v-for="(item, indexList) in items">
              <b-tr
                v-for="(partner, index) in item.partner_assignments"
                :key="`${partner.id}-${indexList}`"
              >
                <b-td
                  v-if="index === 0"
                  :rowspan="item.partner_assignments.length"
                  class="align-top text-[#333333] text-md"
                >
                  {{ indexList + 1 }}
                </b-td>
                <b-td
                  v-if="index === 0"
                  :rowspan="item.partner_assignments.length"
                  class="align-top text-[#333333] px-0"
                >
                  <div class="flex items-center">
                    <img
                      v-if="item.photo_profile_url === ''"
                      src="https://storage.googleapis.com/komerce/assets/avatars/image-null.png"
                      alt="Komerce"
                      class="w-[40px] h-[40px] rounded-circle"
                    >
                    <img
                      v-else
                      :src="item.photo_profile_url"
                      onerror="this.onerror=null;this.src='https://storage.googleapis.com/komerce/assets/avatars/image-null.png';"
                      alt="Komerce"
                      class="w-[40px] h-[40px] rounded-circle object-fit-cover"
                    >
                    <div class="ml-1">
                      {{ item.full_name }}
                    </div>
                  </div>
                </b-td>
                <b-td
                  v-if="index === 0"
                  :rowspan="item.partner_assignments.length"
                  class="align-top text-[#333333] text-md font-medium px-0"
                >
                  {{ item.skill_name }}
                </b-td>
                <b-td
                  class="align-top text-[#333333] text-md font-medium px-0"
                >
                  {{ partner.partner_name }}
                </b-td>
                <b-td
                  class="align-top text-[#333333] text-md font-medium px-0"
                >
                  {{ partner.talent_lead_name }}
                </b-td>
                <b-td
                  v-if="index === 0"
                  :rowspan="item.partner_assignments.length"
                  class="align-top text-[#333333] text-md font-medium px-0"
                >
                  {{ item.rating }}
                </b-td>
                <b-td
                  v-if="index === 0"
                  :rowspan="item.partner_assignments.length"
                  class="align-top text-[#333333] text-md font-medium min-w-[50px] px-0"
                >
                  <div class="flex justify-center items-center">
                    <BFormCheckbox
                      :checked="item.is_visible > 0 ? true : false"
                      switch
                      @change="onUpdate($event, item.id)"
                    />
                  </div>
                </b-td>
                <b-td
                  v-if="index === 0"
                  :rowspan="item.partner_assignments.length"
                  class="align-top text-[#333333] text-md font-medium min-w-[50px] p-0"
                >
                  <div class="flex justify-end items-center">
                    <BButton
                      class="btn-icon mr-50"
                      size="sm"
                      variant="flat-warning"
                      :to="{
                        name: $route.meta.routeEdit,
                        params: { id: item.id }
                      }"
                      tag="router-link"
                    >
                      <img
                        src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/edit.svg"
                        alt="Komerce"
                        class="mr-[5px]"
                      >
                    </BButton>
                  </div>
                </b-td>
              </b-tr>
            </template>
          </b-tbody>
        </b-table-simple>
      </div>
    </BOverlay>
  </div>
</template>

<script>
import { komtimAxiosIns } from '@/libs/axios'
import { alertError, alertSuccess } from '@toast'
import { tableHiredMultiple } from '../config'

export default {
  data() {
    return {
      loading: false,
      items: [],
      limit: 20,
      offset: 0,
      lastData: false,
      keyword: '',
      fields: tableHiredMultiple,
      idItem: 0,
      filterSelected: [],
      filterRatingSelected: [],
      listSkills: [],
      alertError,
      alertSuccess,
      filterRatingOptions: [
        { star: 5, value: 5 },
        { star: 4, value: 4 },
        { star: 3, value: 3 },
        { star: 2, value: 2 },
        { star: 1, value: 1 },
      ],
    }
  },
  computed: {
    skill() { return this.filterSelected.join(',') },
    rating() { return this.filterRatingSelected.join(',') },
  },
  mounted() {
    this.getListTalent()
    this.getListSkills()
  },
  methods: {
    handleScroll() {
      const table = document.getElementById('table')
      if ((table.scrollTop >= table.scrollHeight - table.offsetHeight) && !this.loading && !this.lastData) {
        this.getNextData()
      }
    },
    async getListTalent() {
      this.loading = true
      this.offset = 0
      const params = `offset=${this.offset}&limit=${this.limit}&status=multiple&skill=${this.skill}&rating=${this.rating}&keyword=${this.keyword}&mode=display`
      const url = `v1/talents?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data, meta } = res.data
          this.items = data
          this.offset = data?.length
          this.$emit('updateTotal', meta.total)

          if (data?.length < this.limit) {
            this.lastData = true
          } else {
            this.lastData = false
          }
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    async getNextData() {
      this.loading = true
      if (!this.lastData) {
        const params = `offset=${this.offset}&limit=${this.limit}&status=multiple&skill=${this.skill}&rating=${this.rating}&keyword=${this.keyword}&mode=display`
        const url = `v1/talents?${params}`

        await komtimAxiosIns.get(url)
          .then(res => {
            const { data } = res.data
            this.items.push(...data)
            this.offset += data.length
            this.loading = false
            if (data.length < this.limit) {
              this.lastData = true
            }
          })
          .catch(err => {
            this.alertError(err)
          })
      }
    },
    handleSearch: _.debounce(function search() {
      this.getListTalent()
    }, 1000),
    selectItem(id) {
      this.idItem = id
    },
    async getListSkills() {
      this.loading = true
      this.offset = 0
      const params = `active=${true}&type=2`
      const url = `v1/skills/resource?${params}`

      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.listSkills = data
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    async onUpdate(visible, id) {
      const payload = {
        is_visible: visible ? 1 : 0,
      }

      const url = `v1/talents/${id}/visible_action`
      await komtimAxiosIns
        .post(url, payload)
        .then(() => {
          this.alertSuccess('Berhasil mengubah data')
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },
    resetFilter() {
      this.filterSelected = []
      this.filterRatingSelected = []
      this.getListTalent()
    },
  },

}
</script>
<style scoped lang="scss">
@import '../style/Registered.scss';

.selected {
  background: #DCF3EB;
  border-radius: 4px;
  color: #34A770;
  font-weight: 500;
  font-size: 14px;
  padding: 3px 10px;
  width: 100%;
}
</style>
